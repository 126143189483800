import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1650a338 = () => interopDefault(import('..\\pages\\about\\index.vue' /* webpackChunkName: "pages/about/index" */))
const _6f42f4a3 = () => interopDefault(import('..\\pages\\business\\index.vue' /* webpackChunkName: "pages/business/index" */))
const _eafb9ec6 = () => interopDefault(import('..\\pages\\cahandle\\index.vue' /* webpackChunkName: "pages/cahandle/index" */))
const _00011edb = () => interopDefault(import('..\\pages\\download\\index.vue' /* webpackChunkName: "pages/download/index" */))
const _543ceb95 = () => interopDefault(import('..\\pages\\guide\\index.vue' /* webpackChunkName: "pages/guide/index" */))
const _69f7bdf0 = () => interopDefault(import('..\\pages\\login\\index.vue' /* webpackChunkName: "pages/login/index" */))
const _7be397a0 = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _98b10892 = () => interopDefault(import('..\\pages\\outside-cjwt\\index.vue' /* webpackChunkName: "pages/outside-cjwt/index" */))
const _234069e0 = () => interopDefault(import('..\\pages\\register\\index.vue' /* webpackChunkName: "pages/register/index" */))
const _116eaf50 = () => interopDefault(import('..\\pages\\business\\detail.vue' /* webpackChunkName: "pages/business/detail" */))
const _6477c31e = () => interopDefault(import('..\\pages\\business\\detail-sd.vue' /* webpackChunkName: "pages/business/detail-sd" */))
const _4946cc82 = () => interopDefault(import('..\\pages\\business\\index copy.vue' /* webpackChunkName: "pages/business/index copy" */))
const _6daaf436 = () => interopDefault(import('..\\pages\\login\\index-zfcg.vue' /* webpackChunkName: "pages/login/index-zfcg" */))
const _180b6163 = () => interopDefault(import('..\\pages\\news\\detail.vue' /* webpackChunkName: "pages/news/detail" */))
const _0f7cd934 = () => interopDefault(import('..\\pages\\business\\components\\file-download.vue' /* webpackChunkName: "pages/business/components/file-download" */))
const _b2706ce4 = () => interopDefault(import('..\\pages\\business\\components\\link-paper.vue' /* webpackChunkName: "pages/business/components/link-paper" */))
const _af8b50fa = () => interopDefault(import('..\\pages\\business\\components\\search-bar.vue' /* webpackChunkName: "pages/business/components/search-bar" */))
const _684b94bc = () => interopDefault(import('..\\pages\\business\\components\\search-bar copy.vue' /* webpackChunkName: "pages/business/components/search-bar copy" */))
const _5bfa084a = () => interopDefault(import('..\\pages\\business\\components\\table-hxrgs.vue' /* webpackChunkName: "pages/business/components/table-hxrgs" */))
const _590badb3 = () => interopDefault(import('..\\pages\\register\\components\\btn-yzm.vue' /* webpackChunkName: "pages/register/components/btn-yzm" */))
const _194fde32 = () => interopDefault(import('..\\pages\\register\\components\\captcha.vue' /* webpackChunkName: "pages/register/components/captcha" */))
const _527f3121 = () => interopDefault(import('..\\pages\\register\\components\\input-yzm.vue' /* webpackChunkName: "pages/register/components/input-yzm" */))
const _9688e3e0 = () => interopDefault(import('..\\pages\\register\\components\\tos.vue' /* webpackChunkName: "pages/register/components/tos" */))
const _73dcfb23 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _3d3597bd = () => interopDefault(import('..\\pages\\index\\sections\\banner.vue' /* webpackChunkName: "pages/index/sections/banner" */))
const _965957de = () => interopDefault(import('..\\pages\\index\\sections\\business.vue' /* webpackChunkName: "pages/index/sections/business" */))
const _4af79dac = () => interopDefault(import('..\\pages\\index\\sections\\enters.vue' /* webpackChunkName: "pages/index/sections/enters" */))
const _4233548e = () => interopDefault(import('..\\pages\\index\\sections\\footlink.vue' /* webpackChunkName: "pages/index/sections/footlink" */))
const _139467bb = () => interopDefault(import('..\\pages\\index\\sections\\logins.vue' /* webpackChunkName: "pages/index/sections/logins" */))
const _c9bfbbb8 = () => interopDefault(import('..\\pages\\index\\sections\\news.vue' /* webpackChunkName: "pages/index/sections/news" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _1650a338,
    alias: "/about/index.html",
    name: "about"
  }, {
    path: "/business",
    component: _6f42f4a3,
    alias: "/business/index.html",
    name: "business"
  }, {
    path: "/cahandle",
    component: _eafb9ec6,
    alias: "/cahandle/index.html",
    name: "cahandle"
  }, {
    path: "/download",
    component: _00011edb,
    alias: "/download/index.html",
    name: "download"
  }, {
    path: "/guide",
    component: _543ceb95,
    alias: "/guide/index.html",
    name: "guide"
  }, {
    path: "/login",
    component: _69f7bdf0,
    alias: "/login/index.html",
    name: "login"
  }, {
    path: "/news",
    component: _7be397a0,
    alias: "/news/index.html",
    name: "news"
  }, {
    path: "/outside-cjwt",
    component: _98b10892,
    alias: "/outside-cjwt/index.html",
    name: "outside-cjwt"
  }, {
    path: "/register",
    component: _234069e0,
    alias: "/register/index.html",
    name: "register"
  }, {
    path: "/business/detail",
    component: _116eaf50,
    alias: "/business/detail/index.html",
    name: "business-detail"
  }, {
    path: "/business/detail-sd",
    component: _6477c31e,
    alias: "/business/detail-sd/index.html",
    name: "business-detail-sd"
  }, {
    path: "/business/index%20copy",
    component: _4946cc82,
    alias: "/business/index%20copy/index.html",
    name: "business-index copy"
  }, {
    path: "/login/index-zfcg",
    component: _6daaf436,
    alias: "/login/index-zfcg/index.html",
    name: "login-index-zfcg"
  }, {
    path: "/news/detail",
    component: _180b6163,
    alias: "/news/detail/index.html",
    name: "news-detail"
  }, {
    path: "/business/components/file-download",
    component: _0f7cd934,
    alias: "/business/components/file-download/index.html",
    name: "business-components-file-download"
  }, {
    path: "/business/components/link-paper",
    component: _b2706ce4,
    alias: "/business/components/link-paper/index.html",
    name: "business-components-link-paper"
  }, {
    path: "/business/components/search-bar",
    component: _af8b50fa,
    alias: "/business/components/search-bar/index.html",
    name: "business-components-search-bar"
  }, {
    path: "/business/components/search-bar%20copy",
    component: _684b94bc,
    alias: "/business/components/search-bar%20copy/index.html",
    name: "business-components-search-bar copy"
  }, {
    path: "/business/components/table-hxrgs",
    component: _5bfa084a,
    alias: "/business/components/table-hxrgs/index.html",
    name: "business-components-table-hxrgs"
  }, {
    path: "/register/components/btn-yzm",
    component: _590badb3,
    alias: "/register/components/btn-yzm/index.html",
    name: "register-components-btn-yzm"
  }, {
    path: "/register/components/captcha",
    component: _194fde32,
    alias: "/register/components/captcha/index.html",
    name: "register-components-captcha"
  }, {
    path: "/register/components/input-yzm",
    component: _527f3121,
    alias: "/register/components/input-yzm/index.html",
    name: "register-components-input-yzm"
  }, {
    path: "/register/components/tos",
    component: _9688e3e0,
    alias: "/register/components/tos/index.html",
    name: "register-components-tos"
  }, {
    path: "/",
    component: _73dcfb23,
    alias: "/index.html",
    name: "index",
    children: [{
      path: "sections/banner",
      component: _3d3597bd,
      name: "index-sections-banner"
    }, {
      path: "sections/business",
      component: _965957de,
      name: "index-sections-business"
    }, {
      path: "sections/enters",
      component: _4af79dac,
      name: "index-sections-enters"
    }, {
      path: "sections/footlink",
      component: _4233548e,
      name: "index-sections-footlink"
    }, {
      path: "sections/logins",
      component: _139467bb,
      name: "index-sections-logins"
    }, {
      path: "sections/news",
      component: _c9bfbbb8,
      name: "index-sections-news"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
