import { render, staticRenderFns } from "./news.vue?vue&type=template&id=792cdd88&scoped=true"
import script from "./news.vue?vue&type=script&lang=js"
export * from "./news.vue?vue&type=script&lang=js"
import style0 from "./news.vue?vue&type=style&index=0&id=792cdd88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "792cdd88",
  null
  
)

export default component.exports