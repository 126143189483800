export default {
    head: {
      aboutus: "<p>惠泽招电子招投标交易平台</p>",
      address: "",
      citycode: "640000",
      guid: "AEE20DFD3BC94C2D8CB7FCF173D88137",
      icp: "宁ICP备2023000622号-1",
      gwIcp: '宁公网安备64010602001060',
      logofileguid: "",
      logourl:"/imgs/logo.png",
      meta: "惠泽招电子招投标交易平台",
      planname: "nxhzz",
      tel: "400-630-6886",
      email: 'nxhuizezhao@163.com',
      title: "惠泽招电子招投标交易平台",
      address:'宁夏回族自治区银川市金凤区长城中路街道盈华商厦西塔17层',
      },
      personlist: [
        {
          imgsrc: '/img/hzzlogin/tb.png',
          href: 'http://tb.huizezhao.cn/#/login?plan=nxhzz',
          con: '投标人登录'
        },
        {
          imgsrc: '/img/hzzlogin/zb.png',
          href: 'http://dl.huizezhao.cn/otc/#/login?plan=nxhzz&mRole=agent',
          con: '招标代理登录'
        },
        {
          imgsrc: '/img/hzzlogin/cg.png',
          href: 'http://dl.huizezhao.cn/otc/#/login?plan=nxhzz&mRole=tender',
          con: '采购人登录'
        },
        {
          imgsrc: '/img/hzzlogin/pb.png',
          href: 'http://pb.huizezhao.cn/?plan=nxhzz',
          con: '评标登录'
        }

      ],
      personlistZfcg: [
        {
          imgsrc: '/img/hzzlogin/tb.png',
          href: 'http://tb.huizezhao.cn/#/login?plan=nxhzz',
          con: '投标人登录'
        },
        {
          imgsrc: '/img/hzzlogin/zb.png',
          href: 'http://dl.huizezhao.cn/otc/#/login?plan=nxhzz&mRole=agent',
          subHref: 'http://zfcgdl.huizezhao.cn/otc/#/login?plan=nxhzz&mRole=agent',
          con: '招标代理登录'
        },
        {
          imgsrc: '/img/hzzlogin/cg.png',
          href: 'http://zfcgdl.huizezhao.cn/otc/#/login?plan=nxhzz&mRole=tender',
          con: '采购人登录'
        },
        {
          imgsrc: '/img/hzzlogin/pb.png',
          href: 'http://pb.huizezhao.cn/?plan=nxhzz',
          con: '评标登录'
        }

      ],
      urlList: [
        {
          address: 'http://www.nxggzyjy.org/',
          text: '宁夏回族自治区公共资源交易中心网站'
        },
        {
          address: 'http://www.ccgp-ningxia.gov.cn/public/NXGPPNEW/dynamic/index.jsp?id=1',
          text: '宁夏回族自治区政府采购网'
        },
        {
          address: 'https://www.nx.gov.cn/',
          text: '宁夏回族自治区人民政府网站'
        },
        {
          address: 'http://www.cebpubservice.com/',
          text: '中国招投标公共服务平台'
        },
      ]
    }
    