<template>
  <div class="index">
    <div class="totop">
      <banner></banner>

      <!-- <div class="content sec2">
            <el-row :gutter="12">
                <el-col :span="18">
                    <news></news>
                </el-col>
                <el-col :span="6">
                    <enters></enters>
                </el-col>
            </el-row>
        </div> -->
      <div class="section-business">
        <business></business>
      </div>
      <div class="content">
        <news></news>

      </div>
      <footlink></footlink>
    </div>

    <!-- 悬浮框 -->
    <div class="ad">
        <div class="text">
            <label>惠泽招电子招投标交易平台</label>现在面向社会征集专家，各位专家老师有入库意向的请提交入库申请
            <el-link href="http://zjrk.huizezhao.cn/#/pages/expert/apply?plan=nxhzz" type="primary" target="_blank">点击链接</el-link> 或扫描下方二维码
        </div>
        <img src="/images/qrcode.png" />
        <div class="tel">
            <div>咨询电话</div>
            <div>15595223050</div>
        </div>
    </div>
    <!-- ca弹窗 -->
    <!-- <float-layer v-if="showView" :json="json" @closeView="closeView"></float-layer> -->

    <hotDialog></hotDialog>

  </div>
</template>

<script>
import Logins from './index/sections/logins'
import Banner from './index/sections/banner';
import News from './index/sections/news';
import Enters from './index/sections/enters';
import Business from './index/sections/business';
import Footlink from './index/sections/footlink'

import floatLayer from '@components/sys/float-layer';
import hotDialog from '@/components/sys/question/hot-dialog.vue';

import appNode from '@js/app-node';

export default {
  components: {
    Logins,
    Banner,
    News,
    Enters,
    Business,
    floatLayer,
    Footlink,
    hotDialog,
  },

  data: () => ({
    showView: true,
    json: {
      left: 100,
      top: 400,
      directionX: 1,
      directionY: 1,
      distance: null,
      interval: null,
      target: null,
      zIndex: null,
      width: null,
      height: null,
      noHoverPause: null, //鼠标经过时不停止，为true时不停止
      cursor: null,
    },
  }),
  methods: {
    closeView (val) {
      this.showView = false;
    },
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.sec2 {
  margin-top: 60px;
}

.section-business{
  background: #F9FAFD;
}

.ad{
    position: fixed;
    left: 20px;
    bottom: 20%;
    width: 240px;
    background: #fff;
    color:#000;
    padding: 20px 20px 30px;
    border: 5px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
    text-align: center;
    font-size: 15px;

    .text{
        word-break: break-all;
        text-align: left;
    }

    img{
        width: 140px;
        height: 140px;
        margin-top:10px;
    }

    .tel{
        line-height: 25px;
        color:#000
    }

}
</style>
