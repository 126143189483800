import { render, staticRenderFns } from "./my-table.vue?vue&type=template&id=378533d0&scoped=true"
import script from "./my-table.vue?vue&type=script&lang=js"
export * from "./my-table.vue?vue&type=script&lang=js"
import style0 from "./my-table.vue?vue&type=style&index=0&id=378533d0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "378533d0",
  null
  
)

export default component.exports